import React, { Component } from 'react';
import classNames from 'classnames';
import { PulseLoader } from 'react-spinners';
import { toEnglishDigits } from '../../shared/StringUtils';
import Api from '../../shared/networking/Api';
import jsCookie from 'js-cookie';
import { setAuthToken } from '../../shared/AccountManager';

class ConfirmPhone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timer: 60,
      code: props.codeFromServer ? props.codeFromServer : '',
      loading: false,
      error: null,
      timeIsOver: false,
      sendCodeLoading: false,
    };

    this.timerInterval = setInterval(this.tickTimer, 1000);
  }

  componentDidMount() {
    const { codeFromServer } = this.props;
    if (codeFromServer) {
      this.login();
    }
  }

  tickTimer = () => {
    const { timer } = this.state;
    if (timer === 0) {
      clearInterval(this.timerInterval);
      this.setState({
        timeIsOver: true,
      });
      return;
    }

    this.setState({
      timer: timer - 1,
    });
  };

  setCode = (code) => {

    this.setState({
      error: null,
    });

    let validCode = toEnglishDigits(code);

    this.setState({code: validCode})
  };

  login = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      error: null,
      loading: true,
    });

    let { code } = this.state;
    let { username, didLogin } = this.props;

    Api(false).authService.requestToken(username, code, {
      success: (response) => {
        this.setState({loading: false});
        if (response && response.access_token && response.refresh_token) {
          setAuthToken(response.access_token, response.refresh_token);
          if (didLogin) {
            didLogin();
          }
        }
      },
      error: (code) => {
        if (code >= 400 && code < 500) {
          this.setState({
            error: 'کد وارد شده معتبر نیست',
            loading: false,
          });
        } else if (code >= 500) {
          this.setState({
            error: 'خطا در برقراری ارتباط با سرور',
            loading: false,
          });
        }
      },
    });
  };

  resendCode = (e) => {
    e.preventDefault();

    const { username } = this.props;

    this.setState({
      sendCodeLoading: true,
    });

    Api(false).authService.generate(username, {
      success: () => {
        this.setState({
          timer: 60,
          timeIsOver: false,
          sendCodeLoading: false,
        });

        this.timerInterval = setInterval(this.tickTimer, 1000);
      },
      error: (code) => {
        if (code === 429) {
          this.setState({
            loading: false,
            error: 'خطا به دلیل درخواست های پیاپی',
          });
        } else {
          this.setState({
            loading: false,
            error: 'خطا در ارتباط با سرور',
          });
        }
      },
    });
  };

  render() {
    const { username, onChangeNumberClicked } = this.props;
    const { timer, code, loading, error, timeIsOver, sendCodeLoading } = this.state;

    const isCodeValid = code && code.length > 4;

    return (
      <div className="login">
        <div className="header">
          <a href="#" onClick={onChangeNumberClicked}><img src="/images/icon-back.svg" alt="" /> <span>اصلاح شماره</span></a>
        </div>
        <div className="login">
          <div className="phone-form container">
            <h2>وارد کردن کد تایید</h2>
            <p id="confirm-code-message">کد تایید تا لحظاتی دیگر برای شماره {username ? username.replace('+98', '0') : ''} ارسال می شود لطفا آن را وارد کنید</p>
            <form onSubmit={this.login}>
              <label htmlFor="code" className={classNames({error: error !== null})}>{error ? error : 'کد تایید'}</label>
              <input id="code" type="tel" onChange={(e) => {this.setCode(e.target.value)}} value={code}  className={classNames({error: error !== null})} />
              <button id="confirm-submit-button" type="submit" disabled={!isCodeValid}>{loading ? <PulseLoader css={{'text-align': 'center'}} color="#fff" size="8" /> : 'ورود'}</button>
            </form>
            { timeIsOver ? (sendCodeLoading ? <PulseLoader css={{'text-align': 'center'}} color="#027aff" size="5" /> : <a href="#" className="send-code-button" onClick={this.resendCode}>ارسال مجدد کد</a>) : <p>ارسال مجدد کد پس از <span className="time">{timer}</span> ثانیه</p>}
          </div>
        </div>
      </div>
    );
  }

}

export default ConfirmPhone;
