import BaseService from "./BaseService";

class OrdersService extends BaseService {
  /**
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(httpClient) {
    super();
    this.httpClient = httpClient;
  }

  userSubscriptionPackages = (callback, onlyActive = true) => {
    this.httpClient
      .get(
        `/orders/order/subscriptionPackages?onlyActive=${onlyActive}&ignoreBundles=true`,
      )
      .then((response) => {
        this.onResponse(response, callback);
      })
      .catch((error) => {
        this.onError(error, callback);
      });
  };

  orders = (callback) => {
    this.httpClient
      .get(`/orders/order`)
      .then((response) => {
        this.onResponse(response, callback);
      })
      .catch((error) => {
        this.onError(error, callback);
      });
  };

  discountCodeDetails = (packageId, code, callback) => {
    const username = localStorage.getItem("PROFILE_USERNAME");
    this.httpClient
      .get(`/orders/vouchers/${code}?subscriptionPackageId=${packageId}`)
      .then((response) => {
        // window.dataLayer.push({
        //   event: "discount_code_fetch_success",
        //   packageId,
        //   code,
        //   username,
        // });
        this.onResponse(response, callback);
      })
      .catch((error) => {
        // window.dataLayer.push({
        //   event: "discount_code_fetch_fail",
        //   packageId,
        //   code,
        //   username,
        // });
        this.onError(error, callback);
      });
  };
}

export default OrdersService;
